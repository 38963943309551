<template>
  <div id="loginWrap">
    <form @submit.prevent="submitForm">
      <div class="logo"></div>
      <div class="input_wrap login_box">
        <div class="login_input">
          <label>사업자 번호</label>
          <div class="login_input_text">
            <input
              type="text"
              inputmode="decimal"
              class="input_login_text"
              v-model="saupNo"
              @input="formatSaup($event)"
            />
          </div>
          <span class="error_msg" :class="{ active: error_saup }"
            >사업자 번호를 확인해주세요.</span
          >
        </div>
        <div class="login_input">
          <label>ID</label>
          <div class="login_input_text">
            <input type="text" class="input_login_text2" v-model="userId" />
          </div>
          <span class="error_msg" :class="{ active: error_saup }"
            >아이디를 확인해주세요.</span
          >
        </div>
        <div class="login_input">
          <label>Password</label>
          <div class="login_input_text login_input_password">
            <input
              type="password"
              class="input_login_text3"
              v-model="password"
            />
            <button
              type="button"
              class="input_reset"
              @click="() => (password = '')"
            ></button>
          </div>
          <span class="error_msg" :class="{ active: error_saup }"
            >비밀번호를 확인해주세요.</span
          >
        </div>
      </div>
      <div class="chk_wrap login_box">
        <div class="input_checkbox">
          <label
            for="checkbox1"
            class="chk_box"
            :class="{ active: chk_save }"
            @click="chk_save = !chk_save"
          >
            <i class="fa fa-check"></i>
          </label>
          <input type="checkbox" id="checkbox1" checked />
          <label for="checkbox1" class="label_txt" @click="chk_save = !chk_save"
            >사업자 번호&ID 저장하기</label
          >
        </div>
        <!-- <div class="input_checkbox">
          <label
            for="checkbox2"
            class="chk_box"
            :class="{ active: chk_auto }"
            @click="chk_auto = !chk_auto"
          >
            <i class="fa fa-check"></i>
          </label>
          <input type="checkbox" id="checkbox2" />
          <label for="checkbox2" class="label_txt" @click="chk_auto = !chk_auto"
            >자동로그인</label
          >
        </div> -->
      </div>
      <div class="btn_wrap login_box">
        <!-- <button
          class="btn_cancel"
          onclick="modalClose('#loginModalWrap')"
          type="reset"
        > -->
        <!-- 취소
        </button> -->
        <button
          type="submit"
          class="btn_check"
          :disabled="!userId || !password"
        >
          로그인
        </button>
      </div>
    </form>
    <reset-password-modal
      v-if="showModal"
      :isModalOpen="showModal"
      @OnYesClick="
        $event => {
          resetPassword($event);
          showModal = false;
        }
      "
      @onclose="
        () => {
          user_id_temp = null;
          showModal = false;
        }
      "
    >
    </reset-password-modal>
    <two-button-modal
      :modal_title="'경고'"
      :modal_content="
        '현재 로그인하려는 계정이 접속중입니다.<br />로그인하면 기존의 사용자는 접속이 끊깁니다.<br /><br />그래도 로그인하시겠습니까?'
      "
      :isModalOpen="showLoginWarning"
      @onclose="
        showLoginWarning = false;
        responseTemp = null;
      "
      @OnYesClick="
        showLoginWarning = false;
        Login(responseTemp);
      "
    ></two-button-modal>
  </div>
</template>

<script>
import { saupFormatter } from '@/utils/formatter.js';
import { validateSaupNo } from '@/utils/validation.js';
import { mapActions, mapGetters } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import ResetPasswordModal from '@/layouts/components/ResetPasswordModal.vue';
import { getUrlToCookie, getAuthFromCookie } from '@/utils/cookies';
import crypto from 'crypto';
import TwoButtonModal from '@/layouts/components/TwoButtonModal.vue';
// import { OpenIndexedDB } from '@/utils/IndexedDB.js';
export default {
  components: {
    ResetPasswordModal,
    TwoButtonModal,
  },
  mixins: [ModalMixin, SpinnerMixin],
  data() {
    return {
      //data
      saupNo: '',
      userId: '',
      password: '',
      chk_save: false,
      chk_auto: true,

      //password reset
      showModal: false,
      user_id_temp: null,

      //flag
      error_saup: false,
      error_id: false,
      error_password: false,

      // 중복 로그인
      showLoginWarning: false,
      responseTemp: null,
    };
  },
  computed: {
    ...mapGetters([
      'isLogin',
      'getCompanyURL',
      'getUserInfo',
      'getSessionLife',
    ]),
    isSaupNoValid() {
      return validateSaupNo(this.saupNo);
    },
  },
  methods: {
    Login(response) {
      localStorage.removeItem('saupKey');
      localStorage.setItem('saupKey', this.$store.getters.getSystemCompany.id);
      localStorage.removeItem('userSaup');
      localStorage.removeItem('userId');
      if (this.chk_save || this.chk_auto) {
        localStorage.setItem('userSaup', this.saupNo);
        localStorage.setItem('userId', this.userId);
      }
      if (this.chk_auto) {
        localStorage.setItem('companyUrl', getUrlToCookie());
        localStorage.setItem('token', getAuthFromCookie());
      }
      // OpenIndexedDB();
      if (this.$route.params.id != undefined) {
        this.$store
          .dispatch('UPDATE_FCM_TOKEN', {
            id: this.getUserInfo.id,
            token: this.$route.params.id,
          })
          .then(() => {})
          .catch(() => {});
      }
      this.$store
        .dispatch('CREATE_SESSION', {
          user_id: response.data.data.id,
        })
        .then(newSessionId => {
          sessionStorage.setItem(
            'session',
            `{"user_id": "${response.data.data.id}", "key": ${newSessionId}}`,
          );
          this.$router.push('/main');
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로그인 실패',
            '세션 키 저장에 실패했습니다.',
          );
        });
    },
    resetPassword(newPassword) {
      const hashPassword = this.hash(newPassword);
      this.showSpinner();
      this.$store
        .dispatch('RESET_PASSWORD', {
          id: this.user_id_temp,
          password: hashPassword,
          reset_password: false,
        })
        .then(() => {
          this.openOneButtonModal(
            '비밀번호 재설정 성공',
            '변경한 비밀번호로 로그인하여주십시오.',
          );
        })
        .catch(() => {
          this.openOneButtonModal(
            '비밀번호 재설정 실패',
            '재설정 중 오류 발생(네트워크 오류)',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    ...mapActions(['LOGIN_USER', 'REFRESH_TOKEN']),
    formatSaup(e) {
      const el = e.target; // event값
      let inputValue = el.value
        .replace(/[^0-9.]/gi, '')
        .replace(/\s/gi, '')
        .replace(/-/gi, '')
        .substring(0, 10);

      if (inputValue != '') {
        let inputValueLen = el.value.length;
        let sel = el.selectionStart;
        let inputValueOnlyNum = inputValue.replace(/-/gi, '').length;
        let inputValueSlice =
          inputValue.slice(0, sel - 1) + inputValue.slice(sel);

        let beforeValueLen = this.saupNo.length;

        let count = inputValue.match(/-/gi);
        count = count == null ? [] : count;
        let countLen = count.length;
        const chk = [
          inputValueOnlyNum >= 6 && countLen < 2,
          inputValueOnlyNum >= 4 && countLen < 1,
        ];

        if (beforeValueLen > inputValueLen) {
          // 지울때
          if (chk[0]) {
            inputValue = inputValueSlice;
            sel -= 1;
          } else if (chk[1]) {
            inputValue = inputValueSlice;
            sel -= 1;
          }
        } else {
          // 새로 입력할때
          if (chk[0]) {
            sel += 1;
          } else if (chk[1]) {
            sel += 1;
          } else if (
            countLen == 2 &&
            sel < inputValueLen - 1 &&
            inputValue[sel].match('-')
          ) {
            sel += 1;
          }
        }
        const newValue = saupFormatter(inputValue);
        // el.value = this.saupNo = newValue;
        this.saupNo = newValue;
        this.$nextTick(() => {
          el.setSelectionRange(sel, sel);
        });
      } else {
        const newValue = saupFormatter(inputValue);
        this.saupNo = newValue;
      }
    },
    hash(password) {
      // return password;
      return crypto
        .createHmac('sha256', process.env.VUE_APP_SECRET_KEY)
        .update(password)
        .digest('hex');
    },
    async submitForm() {
      try {
        this.showSpinner();
        const hashedPassword = this.hash(this.password);
        this.$store
          .dispatch('FETCH_AND_SELECT_MY_COMPANY', this.saupNo)
          .then(() => {
            this.$store
              .dispatch('LOGIN_USER', {
                //로그인
                account: this.userId,
                password: hashedPassword,
              })
              .then(async response => {
                console.log('@@@@@@response', response);
                if (response.data.data.reset_password) {
                  this.user_id_temp = response.data.data.id;
                  this.showModal = true;
                  return;
                }
                await this.$store
                  .dispatch(
                    'CHECK_SESSION_ACCOUNT',
                    this.$store.getters.getUserId,
                  )
                  .then(async checkedSession => {
                    if (checkedSession != -1) {
                      this.responseTemp = response;
                      this.showLoginWarning = true;
                    } else {
                      await this.Login(response);
                    }
                  })
                  .catch(error => {
                    console.log(error);
                    this.openOneButtonModal(
                      '네트워크 오류',
                      '로그인 중 오류발생',
                    );
                    return;
                  });
                return;
              })
              .catch(err => {
                console.log('err', err);
                // if (value.data.detail != undefined) {
                this.openOneButtonModal(
                  '로그인 오류',
                  '가입하지 않은 계정이거나, 잘못된 비밀번호입니다.',
                );
                // } else {
                //   this.openOneButtonModal(
                //     '로그인 오류',
                //     '서버 및 네트워크에 문제가 있습니다.',
                //   );
                // }
              });
          })
          .catch(value => {
            console.log(value);
            this.openOneButtonModal('접속오류', '네트워크에 문제가 있습니다.');
          });
        this.hideSpinner();
      } catch (error) {
        console.log(error);
        this.openOneButtonModal(
          '접속오류',
          '네트워크 혹은 시스템에 문제가 있습니다.',
        );
        // this.$router.push('/main');
        this.hideSpinner();
      }
      this.hideSpinner();
    },
    async CheckAutoLogin() {
      const beforeSession =
        sessionStorage.getItem('session') != null
          ? JSON.parse(sessionStorage.getItem('session'))
          : null;
      if (beforeSession != null) {
        this.$store
          .dispatch('CHECK_SESSION', beforeSession.user_id)
          .then(dbSession => {
            if (dbSession != beforeSession.key) {
              this.openOneButtonModal(
                '중복접속',
                '다른 곳에서 접속되어 로그아웃 되었습니다.',
              );
              return;
            }
          })
          .catch(error => {
            console.log(error);
            return;
          });
      } else {
        return;
      }
      const oldToken = localStorage.getItem('token') || '';
      const companyUrlTemp = localStorage.getItem('companyUrl') || '';
      if (oldToken != '' && companyUrlTemp != '') {
        this.chk_auto = true;
        const tokenData = {
          account: localStorage.getItem('userId'),
          token: oldToken,
          companyUrl: companyUrlTemp,
        };
        const message = await this.REFRESH_TOKEN(tokenData);
        if (message == 'success') {
          // OpenIndexedDB();
          if (this.$route.params.id != undefined) {
            this.$store
              .dispatch('UPDATE_FCM_TOKEN', {
                id: this.getUserInfo.id,
                token: this.$route.params.id,
              })
              .then(() => {})
              .catch(() => {});
          }
          this.$router.push('/main');
        } else if (message == 'token_mismatch') {
          console.log('OpenTokenMismatchModal');
          this.openOneButtonModal(
            '자동 로그인 오류(토큰정보 불일치)',
            '다시 로그인하여 주십시오.',
          );
        } else if (message == 'system_error') {
          this.openOneButtonModal(
            '자동 로그인 오류(시스템 오류)',
            '다시 로그인하여 주십시오.',
          );
        } else {
          this.openOneButtonModal(
            '자동 로그인 오류(네트워크 오류)',
            '서버 혹은 네트워크에 문제가 있습니다.',
          );
        }
      }
    },
  },
  beforeMount() {
    this.userId = localStorage.getItem('userId') || '';
    this.saupNo = localStorage.getItem('userSaup') || '';

    if (this.userId != '') {
      this.chk_save = true;
    } else {
      this.chk_save = false;
    }
    this.CheckAutoLogin();
  },
};
</script>

<style></style>
