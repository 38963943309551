function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function validateSaupNo(saupNo) {
  if (saupNo == '111-88-22222') {
    //개발용
    return true;
  }

  // 넘어온 값의 정수만 추츨하여 문자열의 배열로 만들고 10자리 숫자인지 확인합니다.
  if ((saupNo = (saupNo + '').match(/\d{1}/g)).length != 10) {
    return false;
  }
  // 합 / 체크키
  var sum = 0,
    key = [1, 3, 7, 1, 3, 7, 1, 3, 5];
  // 0 ~ 8 까지 9개의 숫자를 체크키와 곱하여 합에더합니다.
  for (var i = 0; i < 9; i++) {
    sum += key[i] * Number(saupNo[i]);
  }
  // 각 8번배열의 값을 곱한 후 10으로 나누고 내림하여 기존 합에 더합니다.
  // 다시 10의 나머지를 구한후 그 값을 10에서 빼면 이것이 검증번호 이며 기존 검증번호와 비교하면됩니다.
  return (
    10 - ((sum + Math.floor((key[8] * Number(saupNo[8])) / 10)) % 10) ==
    Number(saupNo[9])
  );
}

export { validateEmail, validateSaupNo };
