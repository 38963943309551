function saupFormatter(num) {
  num = num.replace(/-/gi, '');

  var formatNum = '';

  try {
    if (num.length > 5 && num.length <= 10) {
      formatNum = num.replace(/(\d{3})(\d{2})(\d{1,5})/, '$1-$2-$3');
    } else if (num.length == 4 || num.length == 5) {
      formatNum = num.replace(/(\d{3})(\d{1,2})/, '$1-$2');
    } else {
      formatNum = num;
    }
  } catch (e) {
    formatNum = num;
    console.log(e);
  }
  return formatNum;
}

function moneyFormatter(num) {
  let number = num;
  if (typeof number == String) {
    number = parseFloat(number);
    if (isNaN(number)) return 0;
  }
  if (number == 0) return 0;
  var reg = /(^[+-]?\d+)(\d{3})/;
  var n = number + '';
  while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
  return n;
}

export { saupFormatter, moneyFormatter };
