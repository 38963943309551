<template>
  <div class="modalComponent">
    <div
      v-show="isModalOpen"
      class="modal reset_pw_modal"
      :style="isModalOpen == false ? 'top: 50%; left: 50%;' : ''"
    >
      <div class="modal_header">
        <h3 class="title">비밀번호 재설정</h3>
        <button class="modal_close" @click="CloseModal"></button>
      </div>
      <div class="modal_body">
        <h2>로그인을 위해 비밀번호를 재설정해주십시오.</h2>
        <div>
          <label>비밀번호</label>
          <div class="reset_input_password">
            <input
              type="password"
              :value="password"
              @input="$event => (password = $event.target.value)"
            />
          </div>
        </div>
        <div>
          <label>재입력</label>
          <div class="reset_input_password">
            <input
              type="password"
              :value="confirm"
              @input="$event => (confirm = $event.target.value)"
            />
          </div>
        </div>
        <div :class="{ 'shake-horizontal': shake }">
          <span v-html="getWarningText" class="warning"></span>
        </div>
      </div>
      <div class="modal_footer">
        <button @click="BtnOkClick">확인</button>
      </div>
    </div>
    <div v-if="isModalOpen" class="modal_layer"></div>
  </div>
</template>

<script>
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
export default {
  data() {
    return {
      password: '',
      confirm: '',
      shake: false,
    };
  },
  computed: {
    checkPassword() {
      let check = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,15}$/;
      return check.test(this.password);
    },
    continuity() {
      let regexNo = /(\w)\1\1/;
      return !regexNo.test(this.password);
    },
    specialChar() {
      // eslint-disable-next-line
      let sChar = /([\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"])\1\1/;
      return !sChar.test(this.password);
    },
    isPasswordValid() {
      if (this.password.length > 0) {
        if (!this.checkPassword) {
          return false;
        } else if (!this.continuity || !this.specialChar) {
          return false;
        } else if (this.password != this.confirm) {
          return false;
        } else return true;
      } else return false;
    },
    getWarningText() {
      if (this.password.length > 0) {
        if (!this.checkPassword) {
          return `비밀번호는 8자리이상 15자리 이하로<br />영문,숫자,특수문자가 반드시 포함되어야 합니다.<br />(사용가능 특수문자 : @$!%*#?&)`;
        } else if (!this.continuity || !this.specialChar) {
          return '동일한 문자나 숫자를 3번이상 연속하여 등록할 수 없습니다.';
        } else if (this.password != this.confirm) {
          return '비밀번호가 서로 다릅니다.';
        } else return '';
      } else return '';
    },
  },
  mixins: [DRAG_MODAL_MIXIN],
  props: {
    isModalOpen: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: false,
    },
  },
  methods: {
    BtnOkClick() {
      if (this.isPasswordValid) this.$emit('OnYesClick', this.password);
      else this.shake = true;
      setTimeout(() => (this.shake = false), 800);
    },
    CloseModal() {
      this.$emit('onclose');
    },
  },
};
</script>

<style scoped>
.shake-horizontal {
  -webkit-animation: shake-horizontal 0.8s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
</style>
